import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { currencyService } from 'src/api/services/currency';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { regionService } from 'src/api/services/region-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { useEditCompanyContext } from '../../context';
import { BasicInformationSchemaFields, basicInformationSchema } from '../../../types/basic-information-form-fields-schema';
import { IBasicInformationFormSchema } from '../../../types/basic-information-form-schema';
import { basicInformationValidationSchema } from '../../../validationSchemas/basic-information-validation-schema';
import { IEditCompanyFormValues } from '../../types/form-values';
import { ISelectBox } from 'src/api/types/base-types';
import { IDataTableResponse } from '../../../../../api/types/table';
import { tagsService } from 'src/api/services/tags-service';

const BasicInformationCompanyForm: React.FC = () => {
    const { formFieldsData, setFormFieldsData, setFormValues, formValues } = useEditCompanyContext();
    const [formSchema, setFormSchema] = useState<Record<BasicInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(basicInformationSchema);
    const countryResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();

    const {
        control,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm<IBasicInformationFormSchema>({
        defaultValues: generateDefaultValueFromFormSchema(basicInformationSchema),
        mode: 'all',
        resolver: yupResolver(basicInformationValidationSchema),
    });

    const setValues = async () => {
        if (Object.keys(formValues).length) {            
            // Hint: this condition is for those moments we are coming back from next steps.
            Object.entries(formValues).forEach(([key, value]) => {
                if(key === "tags") {
                    console.log("TAGS ARE " , value);
                    
                    setValue(key , (value as any).map((item : any) => +item.id))
                }
                setValue(key as keyof IBasicInformationFormSchema, value, { shouldValidate: true })
            });
        }
    };

    const handleUpdateDropdownOptions = async (fieldName: BasicInformationSchemaFields, getNewOptions: any, lazy?: boolean) => {
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                data: [...(lazy ? prev[fieldName].data || [] : [])],
                props: {
                    ...formSchema[fieldName].props,
                    loading: true,
                },
            },
        }));

        const response = await getNewOptions();
        countryResponseMeta.current = response.data.meta;
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [
                    ...(lazy ? prev[fieldName].data || [] : []),
                    ...response.data.data.map(({ label, value }: any) => ({
                        label,
                        value: fieldName === "tags" ? +value : String(value),
                    })),
                ],
                props: {
                    ...prev[fieldName].props,
                    loading: false,
                    disabled: false,
                    onLazy,
                    onFilter
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(fieldName, () =>
                    regionService.selectBox({
                        ...(value ? { text: value } : {}),
                    })
                );
                break;
            }
            case 'tags': {
                handleUpdateDropdownOptions(fieldName, () =>
                    tagsService.selectBox({
                        ...(value ? { text: value } : {}),
                        ...(getValues('tags') ? {selected_items : [...getValues('tags') as string[]]} : {})
                    })
                );
                break;
            }
        }
    };

    const onLazy = (fieldName: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(
                    fieldName,
                    () =>
                        regionService.selectBox({
                            page: (countryResponseMeta.current?.current_page || 1) + 1,
                        }),
                    true
                );
                break;
            }
        }
    };

    const getFieldOptions = async (countrySearch?: { text?: string }) => {
        let currenciesData: ISelectBox[] = [];
        let countriesData: ISelectBox[] = [];
        let tagsData: ISelectBox[] = [];

        try {
            if (formFieldsData['currency_id']?.length && formFieldsData['country_id']?.length) {
                currenciesData = formFieldsData.currency_id;
                countriesData = formFieldsData.country_id;
            } else {
                const selected_items = formValues.country_id ? [formValues.country_id.toString()] : [];
                const [currencyRes, countryRes , tagsRes] = await Promise.all([
                    currencyService.selectBox(),
                    regionService.selectBox({
                        selected_items,
                    }),
                    tagsService.selectBox({
                        selected_items : [...formValues['tags'] as string[]]
                    })
                ]);
                currenciesData = currencyRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                countriesData = countryRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                tagsData = tagsRes.data.data.map(({ label, value }) => ({
                    label,
                    value: +value,
                }));
                
                setFormFieldsData((prev) => ({ ...prev, currency_id: currenciesData, country_id: countriesData , tags: tagsData }));
            }

            const newFormSchemaState = {
                ...formSchema,
                currency_id: { ...formSchema.currency_id, data: currenciesData, props: { ...formSchema.currency_id.props, disabled: false } },
                country_id: {
                    ...formSchema.country_id,
                    data: countriesData,
                    props: { ...formSchema.country_id.props, disabled: false, onFilter, onLazy },
                },
                slug: { ...formSchema.slug, props: { ...formSchema.slug.props, disabled: true } },
                tags : { ...formSchema.tags, data: tagsData , props: { ...formSchema.tags.props, disabled: false , onFilter, onLazy }}
            };

            setFormSchema(newFormSchemaState);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    useEffect(() => {
        getFieldOptions();
        setValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name) {
                let newData = {};
                // @ts-ignore
                newData = { ...newData, [name as keyof IEditCompanyFormValues]: value[name] };
                setFormValues((prev) => ({ ...prev, ...newData }));
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center">
            <div className="xs:col:12 md:col-6">
                <FormGenerator control={control} errors={errors} schema={formSchema} noBackgroundLayout />
            </div>
        </div>
    );
};

export default BasicInformationCompanyForm;
