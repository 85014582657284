import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { sectorService } from 'src/api/services/sector-service';
import { tagsService } from 'src/api/services/tags-service';
import Button from 'src/components/Kit/Button';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { createSchemaType, createSectorSchema } from './schema';
import { validationSchema } from './validation-schema';

interface IParams {
    [id: string]: string;
}

const AddUpdateTag: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const hasCreatePermission = checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_CREATE)
    const hasUpdatePermission = checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_EDIT)
    const [loading, setLoading] = useState<boolean>(false);
    const [formSchema, setFormSchema] = useState<createSchemaType>(createSectorSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<any>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(validationSchema()),
    });

    const onSubmit = async (_val: any) => {
        console.log("SUBNIT " , _val);
        
        let targetApi;
        const { name } = _val;
        const tempData = {
            name,
        };
        if (isEditingRule && hasUpdatePermission) targetApi = sectorService.update(Number(id), tempData);
        else if(hasCreatePermission) targetApi = tagsService.createTag(tempData)
        try {
            setLoading(true);
            await targetApi;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: isEditingRule ? 'Tag has been updated successfully' : 'Tag has been created successfully',
            });
            setLoading(false);
            navigate(-1);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    if(isEditingRule && !hasUpdatePermission) return <></>;
    if(!isEditingRule && !hasCreatePermission) return <></>;
    
    return (
        <DashboardContentWrapper>
            <div className="grid justify-content-center my-8">
                <div className="xs:col:12 md:col-8 xl:col-5">
                    <span className="block text-2xl font-semibold">{isEditingRule ? 'Update Tag' : 'Add New Tag'}</span>
                    <div className="mt-5">
                        <FormGenerator
                            onSubmit={handleSubmit(onSubmit)}
                            control={control}
                            errors={errors}
                            schema={formSchema}
                            noBackgroundLayout
                            buttons={
                                <>
                                    <Button disabled={loading} loading={loading} color="primary">
                                        {isEditingRule ? 'Update' : 'Create'}
                                    </Button>
                                    <Button type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </Button>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </DashboardContentWrapper>
    );
};

export default AddUpdateTag;
