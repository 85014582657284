import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import { classNames } from 'primereact/utils';
import { RedError } from 'src/assets/Icons/RedError';
import { formEditorConfig } from './config';

interface Props {
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: { required?: boolean };
    field: ControllerRenderProps<FieldValues, string>;
}

const FormEditor: React.FC<Props> = ({ label, placeholder, className, errorMessage, props = {}, field }) => {
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const setValue = (data: string) => {
        field.onChange({ target: { value: data, name: field.name, id: field.name } });
    };

    return (
        <div className={classNames('col-12', className ? className : '')}>
            <div className="form-field">
                <label className={field.name}>
                    {label || placeholder} {props.required ? <span className="required">*</span> : ''}
                </label>
                <Editor
                    apiKey={window.TINY_EDITOR_API_KEY || process.env.TINY_EDITOR_API_KEY || '4ahvb51fbksk7651684atxschomie23evusxi0o0dhrsltm8'}
                    // @ts-ignore
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={field.value}
                    onEditorChange={setValue}
                    init={formEditorConfig}
                />
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormEditor;
