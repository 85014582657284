import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { screenerService } from 'src/api/services/screener-service';
import { TCreateScreenerPayload } from 'src/api/types/screeners';
import { useAsync } from 'src/hooks/useAsync';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';

export type SelectMutationPayload = { type: 'select' | 'deselect' } & TCreateScreenerPayload;
type Options = {
    paramsId: string | undefined;
    setParamsId: Dispatch<SetStateAction<string | undefined>>;
    getValues: (field: string) => string;
};
export function useScreenerCompanySelector({ getValues, paramsId, setParamsId }: Options) {
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const selectMutation = useAsync(
        async (selectPayload: SelectMutationPayload) => {
            try {
                console.log(selectPayload);

                // if (!paramsId) return Promise.reject();
                const payload: TCreateScreenerPayload = {
                    company_ids: selectPayload.company_ids,
                    ...(selectPayload as any),
                };
                if (paramsId && !payload.screener_id) payload.screener_id = +paramsId;
                if (!payload.name) payload.name = getValues('name') as string;

                if (selectPayload.type === 'select') {
                    return await screenerService.modify(payload as TCreateScreenerPayload).then((res) => {
                        setSelectedIds((prev) => [...prev, ...selectPayload.company_ids]);
                        return res;
                    });
                } else if (selectPayload.type === 'deselect') {
                    return await screenerService.delete(+paramsId!, selectPayload.company_ids[0]).then((res) => {
                        setSelectedIds((prev) => prev.filter((id) => !selectPayload.company_ids.includes(id)));
                        return res;
                    });
                }
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        {
            onSuccess: (response: any) => {
                if (response?.data?.data?.id) {
                    setParamsId(response?.data?.data?.id);
                    navigate(ROUTE_CONSTANTS.SETTINGS.SCREENERS.UPDATE.EDIT_BY_ID(response?.data?.data?.id).ABSOLUTE);
                }
                setHasUnsavedChanges(false);
            },
        }
    );

    return {
        selectMutation,
        selectedIds,
        setSelectedIds,
        unsavedChanges: hasUnsavedChanges,
        setUnsavedChanges: setHasUnsavedChanges,
    };
}
