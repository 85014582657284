import EmptyStateIcon from 'src/components/App/EmptyData/icon';
import style from './CompanyFinancialRatio.module.scss'

const CompanyFinancialRatioEmptyState = () => {
    return (
        <div className={style.companyFinancialRatioEmptyState}>
            <EmptyStateIcon/>
            <h4>There Is No Data !</h4>
            <p>
                Financial Ratios Are Not Available For This Company Due To
                <br />
                Insufficient Financial Information
            </p>
        </div>
    );
};

export default CompanyFinancialRatioEmptyState;
