import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';
import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { ISelectBox } from '../types/base-types';
import { IScreenerListResponse, TCreateScreenerPayload } from '../types/screeners';
import { ICompanyTypeResponse } from '../types/sectors';

export const screenerService = {
    getAllScreeners(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IScreenerListResponse>>(API_ROUTES.SCREENER.GET_ALL_SCREENERS, { params });
    },
    getAllCompanies(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IScreenerListResponse>>(API_ROUTES.SCREENER.GET_ALL_SCREENERS_COMPANIES, { params });
    },
    getOne(id: number) {
        return AxiosInstance.get<IAxiosResponse<IScreenerListResponse>>(API_ROUTES.SCREENER.GET_ONE(id));
    },
    getAllCompanyTypes() {
        return AxiosInstance.get<IAxiosResponse<ICompanyTypeResponse[]>>(API_ROUTES.SCREENER.GET_ALL_COMPANY_TYPES);
    },
    modify(data: TCreateScreenerPayload) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.SCREENER.MODIFY, data);
    },
    delete(screenerId: number, companyId: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.SCREENER.DELETE(screenerId, companyId));
    },
    deleteScreener(screenerId: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.SCREENER.DELETE_SCREENER(screenerId));
    },
    selectBox(params?: Partial<TCreateScreenerPayload>) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.SCREENER.GET_ALL_SCREENERS_DROPDOWN_OPTIONS, { params });
    },
};
