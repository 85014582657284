function EmptyStateIcon() {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="153" viewBox="0 0 150 153" fill="none">
                <path
                    d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
                    fill="url(#paint0_linear_9050_71244)"
                />
                <g filter="url(#filter0_d_9050_71244)">
                    <mask id="mask0_9050_71244" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
                        <path
                            d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
                            fill="url(#paint1_linear_9050_71244)"
                        />
                    </mask>
                    <g mask="url(#mask0_9050_71244)">
                        <path
                            d="M118 43H32C29.2386 43 27 45.2386 27 48V153C27 155.761 29.2386 158 32 158H118C120.761 158 123 155.761 123 153V48C123 45.2386 120.761 43 118 43Z"
                            fill="white"
                        />
                    </g>
                </g>
                <path
                    d="M66 53H40C38.3431 53 37 54.3431 37 56C37 57.6569 38.3431 59 40 59H66C67.6569 59 69 57.6569 69 56C69 54.3431 67.6569 53 66 53Z"
                    fill="#E7E7E7"
                />
                <path
                    d="M66 95H40C38.3431 95 37 96.3431 37 98C37 99.6569 38.3431 101 40 101H66C67.6569 101 69 99.6569 69 98C69 96.3431 67.6569 95 66 95Z"
                    fill="#E7E7E7"
                />
                <path
                    d="M108 68H42C39.7909 68 38 69.7909 38 72V82C38 84.2091 39.7909 86 42 86H108C110.209 86 112 84.2091 112 82V72C112 69.7909 110.209 68 108 68Z"
                    stroke="#CCCCCC"
                    strokeWidth="2"
                />
                <path
                    d="M108 109H42C39.2386 109 37 111.239 37 114V122C37 124.761 39.2386 127 42 127H108C110.761 127 113 124.761 113 122V114C113 111.239 110.761 109 108 109Z"
                    fill="#E7E7E7"
                />
                <path
                    d="M53 32C55.2091 32 57 30.2091 57 28C57 25.7909 55.2091 24 53 24C50.7909 24 49 25.7909 49 28C49 30.2091 50.7909 32 53 32Z"
                    fill="white"
                />
                <path
                    d="M75 32C77.2091 32 79 30.2091 79 28C79 25.7909 77.2091 24 75 24C72.7909 24 71 25.7909 71 28C71 30.2091 72.7909 32 75 32Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M97 32C99.2091 32 101 30.2091 101 28C101 25.7909 99.2091 24 97 24C94.7909 24 93 25.7909 93 28C93 30.2091 94.7909 32 97 32Z"
                    fill="white"
                />
                <path
                    d="M86 88C88.7614 88 91 85.7614 91 83C91 80.2386 88.7614 78 86 78C83.2386 78 81 80.2386 81 83C81 85.7614 83.2386 88 86 88Z"
                    fill="#E7E7E7"
                />
                <path
                    d="M89.9071 104.37C89.1071 104.37 88.3601 104.37 87.6801 104.327C86.8425 104.271 86.0367 103.984 85.3515 103.499C84.6662 103.014 84.128 102.349 83.7961 101.578L79.5771 93.2404C79.2676 92.8801 79.1131 92.4121 79.1472 91.9384C79.1813 91.4647 79.4012 91.0236 79.7591 90.7114C80.0522 90.4757 80.418 90.3489 80.7941 90.3524C81.071 90.3604 81.3428 90.4284 81.5909 90.5517C81.839 90.675 82.0574 90.8506 82.2311 91.0664L84.1471 93.6814L84.1761 93.7154V83.7804C84.1761 83.2875 84.3719 82.8148 84.7204 82.4662C85.069 82.1177 85.5417 81.9219 86.0346 81.9219C86.5275 81.9219 87.0002 82.1177 87.3487 82.4662C87.6973 82.8148 87.8931 83.2875 87.8931 83.7804V90.2804C87.8715 90.0412 87.9 89.8001 87.9767 89.5726C88.0534 89.345 88.1767 89.1359 88.3387 88.9586C88.5007 88.7813 88.6979 88.6397 88.9176 88.5429C89.1374 88.446 89.3749 88.396 89.6151 88.396C89.8552 88.396 90.0928 88.446 90.3125 88.5429C90.5323 88.6397 90.7294 88.7813 90.8914 88.9586C91.0534 89.1359 91.1767 89.345 91.2535 89.5726C91.3302 89.8001 91.3587 90.0412 91.3371 90.2804V91.6354C91.3155 91.3962 91.344 91.1551 91.4207 90.9276C91.4974 90.7 91.6207 90.4909 91.7827 90.3136C91.9447 90.1363 92.1419 89.9947 92.3616 89.8979C92.5814 89.801 92.8189 89.751 93.0591 89.751C93.2992 89.751 93.5368 89.801 93.7565 89.8979C93.9763 89.9947 94.1734 90.1363 94.3354 90.3136C94.4974 90.4909 94.6207 90.7 94.6975 90.9276C94.7742 91.1551 94.8027 91.3962 94.7811 91.6354V92.6794C94.7595 92.4402 94.788 92.1991 94.8647 91.9716C94.9414 91.744 95.0647 91.5349 95.2267 91.3576C95.3887 91.1803 95.5859 91.0387 95.8056 90.9419C96.0254 90.845 96.2629 90.795 96.5031 90.795C96.7432 90.795 96.9808 90.845 97.2005 90.9419C97.4203 91.0387 97.6174 91.1803 97.7794 91.3576C97.9414 91.5349 98.0647 91.744 98.1415 91.9716C98.2182 92.1991 98.2467 92.4402 98.2251 92.6794V99.0164C98.1911 100.965 97.3101 104.251 94.2111 104.251C93.9861 104.261 92.0801 104.371 89.9111 104.371L89.9071 104.37Z"
                    fill="#CCCCCC"
                    stroke="white"
                />
                <defs>
                    <filter id="filter0_d_9050_71244" x="21" y="34" width="108" height="119" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="-3" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9050_71244" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9050_71244" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_9050_71244" x1="75" y1="0" x2="75" y2="150" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F9F9F9" />
                        <stop offset="0.0001" stopColor="#F9F9F9" />
                        <stop offset="1" stopColor="#F9F9F9" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_9050_71244" x1="75" y1="0" x2="75" y2="150" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E3ECFA" />
                        <stop offset="1" stopColor="#DAE7FF" />
                    </linearGradient>
                </defs>
            </svg>
     );
}

export default EmptyStateIcon;