import { classNames } from 'primereact/utils';
import styles from '../../CompanyProfile.module.scss';
import { useFinancialCompany } from '../../../../../../context/companies/Companies';
import dayjs from 'dayjs';

const OtherInformation = () => {
    const companyDetails = useFinancialCompany();

    return (
        <div className={classNames(styles.segmentWrapper, 'grid')}>
            <div className={classNames(styles.row, styles.topLeftBorderRadius, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Legal Structure</h4>
                    <h4 className={styles.detail}>{companyDetails?.legal_structure_name}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Listing Status</h4>
                    <h4 className={styles.detail}>{companyDetails?.listing_status}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.topRightBorderRadius, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>ISIN</h4>
                    <h4 className={styles.detail}>{companyDetails?.isin}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Issued Shared</h4>
                    <h4 className={styles.detail}>{companyDetails?.issued_shared}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Founded Year</h4>
                    <h4 className={styles.detail}>{companyDetails?.inception_year ? companyDetails?.inception_year : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Stock Exchange</h4>
                    <h4 className={styles.detail}>{companyDetails?.stock_exchange_name ? companyDetails?.stock_exchange_name : '_'}</h4>
                </div>
            </div>

            <div className={classNames(styles.row, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Chamber Name</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.chamber_name ? companyDetails?.license_registration?.chamber_name : '_'}
                    </h4>
                </div>
            </div>

            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Authority</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.authority_name ? companyDetails?.license_registration?.authority_name : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Chamber Number</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.chamber_number ? companyDetails?.license_registration?.chamber_number : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>License Number</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.license_number ? companyDetails?.license_registration?.license_number : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Registration Website</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.registration_website ? companyDetails?.license_registration?.registration_website : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>License Website</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.license_website ? companyDetails?.license_registration?.license_website : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.bottomLeftBorderRadius, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Registration Date</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.registration_date
                            ? new Date(companyDetails?.license_registration?.registration_date * 1000).toLocaleDateString('en-US')
                            : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.bottomRightBorderRadius, styles.noBorderLeft, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>License Date</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.license_registration?.license_date
                            ? new Date(companyDetails?.license_registration?.license_date * 1000).toLocaleDateString('en-US')
                            : '_'}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.bottomRightBorderRadius, styles.noBorderLeft, styles.noBorderTop, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Sector</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.sector_label
                            ? companyDetails?.sector_label
                            : '_'}
                    </h4>
                </div>
            </div>
            
        </div>
    );
};

export default OtherInformation;
