import { ModalProps } from 'src/types/modals';
import style from './styles.module.scss';
import { useEffect, useMemo } from 'react';
import Table from 'src/components/App/Table';
import { companyDataCellRender } from 'src/pages/Settings/Screeners/AddUpdate/companyDataCellRender';
import { screenerService } from 'src/api/services/screener-service';
import { IDataTablePayload } from 'src/api/types/table';
import { companyDataColumnsRender } from 'src/pages/Settings/Screeners/AddUpdate/companyDataColumnsRender';
import { useScreenerCompanySelector } from 'src/pages/Settings/Screeners/AddUpdate/useScreenerCompanies';
import { ICompanyTypeResponse } from 'src/api/types/legal-structures';
import { useAsync } from 'src/hooks/useAsync';
import { IScreenerListResponse } from 'src/api/types/screeners';

export interface ScreenerCompaniesProps {
    selectedIds: number[];
    paramsId?: string;
    selectMutation: any;
}

const ScreenerCompanies: React.FC<ModalProps<ScreenerCompaniesProps>> = ({ data: { selectedIds, paramsId, selectMutation }, closeModal }) => {
    const { data: screenerData, run } = useAsync(async (paramsId) => {
        if (!paramsId) return;
        const response = await screenerService.getOne(+paramsId);
        const screener = response?.data.data as IScreenerListResponse;
        console.log(response, screener);
        return screener;
    });
    const service = async (queryParams?: IDataTablePayload) => {
        if (!paramsId) return;
        const response = await screenerService.getOne(+paramsId);
        const screener = response?.data.data as IScreenerListResponse;
        return await screenerService.getAllCompanies(queryParams).then((data) => {
            return {
                ...data,
                data: {
                    ...data.data,
                    data: screener?.companies || [],
                    meta: {
                        pagination: (response.data as any).meta.pagination,
                        filters: data.data.meta.filters,
                        customize: ['arabic_name', 'arabic_short_name', 'country_name'],
                        columns: companyDataColumnsRender,
                    },
                },
            };
        });
    };

    useEffect(() => {
        if (paramsId) run(paramsId);
    }, [paramsId]);

    const MemoizedTable = useMemo(() => {
        const cellRenderer = companyDataCellRender({
            mutate: selectMutation.run,
            selectedIds,
            selectDisable: false,
            removeAfterDeselect: true,
        });
        return <Table showHeader={false} getTableData={service as any} cellRender={cellRenderer} />;
    }, [selectMutation?.isLoading, selectedIds]);

    return (
        <div className={style.wrapper}>
            <div className={style.body}>{MemoizedTable}</div>
        </div>
    );
};

export default ScreenerCompanies;
