import { yupResolver } from '@hookform/resolvers/yup';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { screenerService } from 'src/api/services/screener-service';
import { IScreenerListResponse } from 'src/api/types/screeners';
import { IDataTablePayload } from 'src/api/types/table';
import { RedError } from 'src/assets/Icons/RedError';
import DashboardTitle from 'src/components/App/DashboardTitle/index';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { modalActions } from 'src/context/modals';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { MODAL_TYPES } from 'src/types/modals';
import { IParams } from 'src/types/params';
import { object, string } from 'yup';
import style from '../styles.module.scss';
import { companyDataCellRender } from './companyDataCellRender';
import { companyDataColumnsRender } from './companyDataColumnsRender';
import { useScreenerCompanySelector } from './useScreenerCompanies';
import { useAsync } from 'src/hooks/useAsync';

const ScreenersAddUpdate: React.FC = () => {
    const { id: paramsId } = useParams<IParams>();
    const [id, setId] = useState(paramsId);
    const {
        register,
        watch,
        reset,
        formState: { errors, isValid },
        getValues,
    } = useForm({
        resolver: yupResolver(
            object().shape({
                name: string().min(1).required('Name is required'),
            })
        ),
        mode: 'all',
    });

    const { data: screenerData } = useAsync(
        async () => {
            if (!id) return;
            const response = await screenerService.getOne(+id!);
            const screener = response?.data.data as IScreenerListResponse;
            reset({
                name: screener.name,
            });

            setSelectedIds(screener.companies?.map((item) => item.id) || []);
            return screener;
        },
        {
            immediate: true,
        }
    );
    const tableService = async (queryParams?: IDataTablePayload) =>
        await screenerService.getAllCompanies(queryParams).then((data) => ({
            ...data,
            data: {
                ...data.data,
                meta: {
                    ...data.data.meta,
                    columns: companyDataColumnsRender,
                },
            },
        }));

    const { selectedIds, setSelectedIds, selectMutation, unsavedChanges, setUnsavedChanges } = useScreenerCompanySelector({
        paramsId: id,
        setParamsId: setId,
        getValues,
    });

    const handleShowCompanies = (screenerId: string | undefined) => {
        console.log({ screenerId });

        modalActions.addModal(MODAL_TYPES.SCREENER_COMPANIES, {
            header: `${watch('name')} Screener Companies`,
            selectMutation,
            paramsId: screenerId,
            selectedIds,
        });
    };

    const PrefixElement = useMemo(() => {
        return (
            <div className="form-field">
                <label>Name</label>
                <InputText
                    id={'name'}
                    type={'text'}
                    placeholder={'Write a name for Screener'}
                    {...register('name')}
                    onChange={(e) => {
                        if (e.target.value !== screenerData?.name) {
                            setUnsavedChanges(true);
                        } else {
                            setUnsavedChanges(false);
                        }
                        register('name').onChange(e);
                    }}
                />
                {errors['name'] && (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{JSON.stringify(errors)}</span>
                    </div>
                )}
            </div>
        );
    }, []);

    const BottomHolderElement = useMemo(() => {
        if (!isValid) return <></>;
        return (
            <div className={style['bottom-holder']}>
                <div>
                    <span
                        style={{
                            position: 'absolute',
                            left: 4,
                            margin: '0 10px',
                            width: 40,
                        }}
                    >
                        {selectMutation.isLoading ? <div className={style['loading']}></div> : selectedIds.length}
                    </span>
                    <div className="ml-4">Company Selected</div>
                    {Boolean(isValid && unsavedChanges) && (
                        <span
                            style={{
                                color: 'red',
                                fontSize: 12,
                                paddingLeft: 24,
                            }}
                        >
                            Some Changes are not saved, please save your changes
                        </span>
                    )}
                </div>
                <div>
                    <Button
                        disabled={!unsavedChanges || !isValid}
                        color="primary"
                        loading={selectMutation.isLoading}
                        onClick={() =>
                            selectMutation.run({
                                type: 'select',
                                company_ids: [],
                                name: watch('name'),
                            })
                        }
                    >
                        Save
                    </Button>
                    <Button onClick={() => handleShowCompanies(id)} disabled={selectedIds.length === 0}>
                        Show Selected Companies
                    </Button>
                </div>
            </div>
        );
    }, [selectMutation.isLoading, selectedIds.length, isValid, id, unsavedChanges]);

    const MemoizedTable = useMemo(
        () => (
            <Table
                defaultShowFilter={Boolean(id)}
                message={isValid ? '' : 'Please Enter a name for Screener'}
                disabled={!isValid}
                prefixElement={PrefixElement}
                getTableData={tableService as any}
                cellRender={companyDataCellRender({
                    mutate: (payload) =>
                        selectMutation.run({
                            ...payload,
                            screener_id: id as any,
                        }),
                    selectedIds,
                    selectDisable: !isValid,
                })}
            />
        ),
        [selectedIds.length, isValid, id]
    );
    return (
        <>
            <Helmet>
                <title>Teaser | {id ? 'Update' : 'Create'} Screener</title>
            </Helmet>
            <DashboardTitle title={`${id ? 'Update' : 'Create'} Screener`} />
            <DashboardContentWrapper customStyle={{ position: 'relative' }}>
                <div style={{ minHeight: '1000px' }}>{MemoizedTable}</div>
                {BottomHolderElement}
            </DashboardContentWrapper>
        </>
    );
};

export default ScreenersAddUpdate;
