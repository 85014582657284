import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';

export type createSchemaFields = 'name'
export type createSchemaType = Record<createSchemaFields, IFormGeneratorGeneralSchemaType>;
export const createSectorSchema: createSchemaType = {
    name: {
        placeholder: 'Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
};
