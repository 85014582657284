import { createContext, useContext, useEffect, useState } from 'react';
import { IEditCompanyFormValues } from '../types/form-values';
import { IFunctionalComponentProps } from 'src/types/functional-component';
import { IEditCompanyFormContext } from '../types/edit-company-form-context';
import { IFormFieldsData } from 'src/components/Kit/MultiStepForm/types/form-fields-data';
import { useNavigate, useParams } from 'react-router-dom';
import { useBreadcrumbDynamicName } from 'src/hooks/useBreadcrumbDynamicName';
import { companyService } from 'src/api/services/company';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';

interface IProps extends IFunctionalComponentProps {
    onComplete: (formValues: IEditCompanyFormValues, companyId: number) => void;
    onCancel: () => void;
    loading?: boolean;
}

export const EditCompanyContext = createContext<IEditCompanyFormContext>({} as IEditCompanyFormContext);

const EditCompanyProvider: React.FC<IProps> = ({ children, onComplete, onCancel, loading = false }) => {
    const navigate = useNavigate();
    const setDynamicBreadcrumbName = useBreadcrumbDynamicName();
    const { id, companyType } = useParams<IParams>();
    const companyId = Number(id);
    const isCompanyIdValid = !Number.isNaN(id);

    const [formValues, setFormValues] = useState<IEditCompanyFormValues>({});
    const [formFieldsData, setFormFieldsData] = useState<IFormFieldsData>({});
    const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
    const [initLoading, setInitLoading] = useState<boolean>(true);

    const fetchCompany = async () => {
        try {
            setInitLoading(true);
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).show(companyId);
            setDynamicBreadcrumbName(response.data.data.name);

            const responseData = response.data.data;
            const adaptedData = {
                // basic information
                logo_id: { id: undefined, url: responseData.profile_image },
                name: responseData.name,
                arabic_name: responseData.arabic_name,
                currency_id: responseData.currency_id,

                ...(responseData.arabic_short_name && { arabic_short_name: responseData.arabic_short_name }),
                ...(responseData.slug && { slug: responseData.slug }),
                ...(responseData.short_name && { short_name: responseData.short_name }),
                ...(responseData.description && { description: responseData.description }),
                ...(responseData.arabic_description && { arabic_description: responseData.arabic_description }),
                ...(responseData.country_id && { country_id: responseData.country_id }),
                ...(responseData.previous_arabic_name && { previous_arabic_name: responseData.previous_arabic_name }),
                ...(responseData.previous_name && { previous_name: responseData.previous_name }),

                // contact information
                ...(responseData.phone && { phone: responseData.phone }),
                ...(responseData.email && { email: responseData.email }),
                ...(responseData.website && { website: responseData.website }),
                ...(responseData.address && { address: responseData.address }),
                ...(responseData.twitter_link && { twitter: responseData.twitter_link }),
                ...(responseData.instagram_link && { instagram: responseData.instagram_link }),
                ...(responseData.linkedin_link && { linkedin: responseData.linkedin_link }),
                ...(responseData.youtube_link && { youtube: responseData.youtube_link }),
                ...(responseData.arabic_address && { arabic_address: responseData.arabic_address }),
                ...(responseData.latitude && { latitude: responseData.latitude }),
                ...(responseData.longitude && { longitude: responseData.longitude }),

                // other information
                legal_structure_id: responseData.legal_structure_id,
                listing_status: responseData.listing_status,
                ...(responseData.type && { type: responseData.type }),
                ...(responseData.isin && { ISIN: responseData.isin }),
                ...(responseData.issued_shared && { issued_shared: responseData.issued_shared }),
                ...(responseData.inception_year && { inception_date: new Date(new Date(responseData.inception_year).getTime()) }),
                ...(responseData.stock_exchange_id && { stock_exchange_id: responseData.stock_exchange_id }),
                ...(responseData.sector_id && { sector_id: responseData.sector_id }),

                ...(responseData.license_registration?.chamber_id && { chamber_id: responseData.license_registration.chamber_id }),
                ...(responseData.license_registration?.authority_id && { authority_id: responseData.license_registration.authority_id }),
                ...(responseData.license_registration?.chamber_number && { chamber_number: Number(responseData.license_registration.chamber_number) }),
                ...(responseData.license_registration?.license_number && { license_number: Number(responseData.license_registration.license_number) }),
                ...(responseData.license_registration?.registration_website && {
                    registration_website: responseData.license_registration.registration_website,
                }),
                ...(responseData.license_registration?.license_website && { license_website: responseData.license_registration.license_website }),
                ...(responseData.license_registration?.registration_date && {
                    registration_date: new Date(new Date(responseData.license_registration.registration_date * 1000).getTime()),
                }),
                ...(responseData.license_registration?.license_date && {
                    license_date: new Date(new Date(responseData.license_registration.license_date * 1000).getTime()),
                }),
                ...(responseData.tags && {
                    tags : responseData.tags.map(item => (item.id))
                }),
            };

            setFormValues(adaptedData);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            navigate(ROUTE_CONSTANTS.NOT_FOUND.ABSOLUTE, { replace: true });
        } finally {
            setInitLoading(false);
        }
    };

    useEffect(() => {
        if (isCompanyIdValid) fetchCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contextValue = {
        formValues,
        setFormValues,
        formFieldsData,
        setFormFieldsData,
        onCancel,
        loading,
        initLoading,
        saveDisabled,
        setSaveDisabled,
        onComplete: () => {
            onComplete(formValues, companyId);
        },
    };

    return <EditCompanyContext.Provider value={contextValue}>{children}</EditCompanyContext.Provider>;
};

const useEditCompanyContext = (): IEditCompanyFormContext => {
    const context = useContext(EditCompanyContext);

    if (!context) {
        throw new Error('useEditCompanyContext must be used within a EditCompanyProvider');
    }

    return context;
};

export { EditCompanyProvider, useEditCompanyContext };
