import React, { useEffect, useRef, useState } from 'react';
import { basicInformationValidationSchema } from '../../../validationSchemas/basic-information-validation-schema';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { currencyService } from 'src/api/services/currency';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { regionService } from 'src/api/services/region-service';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { IBasicInformationFormSchema } from '../../../types/basic-information-form-schema';
import { useMultiStepFormContext } from 'src/components/Kit/MultiStepForm/context';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { basicInformationSchema, BasicInformationSchemaFields } from '../../../types/basic-information-form-fields-schema';
import { StepConfig } from 'src/components/Kit/MultiStepForm/types/step-config';
import { ISelectBox } from 'src/api/types/base-types';
import { slugify } from 'src/utils/slugify';
import { IDataTableResponse } from '../../../../../api/types/table';
import { tagsService } from 'src/api/services/tags-service';

const BasicInformationCompanyForm: React.FC = () => {
    const { setStepsData, setFormValues, formValues, activeStepIndex, formFieldsData, setFormFieldsData } = useMultiStepFormContext();
    const [formSchema, setFormSchema] = useState<Record<BasicInformationSchemaFields, IFormGeneratorGeneralSchemaType>>(basicInformationSchema);
    const countryResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();
    const tagsResponseMeta = useRef<IDataTableResponse<unknown>['meta']['pagination']>();

    const {
        control,
        getValues,
        setValue,
        watch,
        formState: { errors, isValid, isDirty },
    } = useForm<IBasicInformationFormSchema>({
        defaultValues: generateDefaultValueFromFormSchema(basicInformationSchema),
        mode: 'all',
        resolver: yupResolver(basicInformationValidationSchema),
    });

    console.log(watch());
    
    const handleUpdateDropdownOptions = async (fieldName: BasicInformationSchemaFields, getNewOptions: any, lazy?: boolean) => {
        if (lazy && countryResponseMeta.current && tagsResponseMeta.current) {
            if (countryResponseMeta.current.last_page === countryResponseMeta.current.current_page) return;
            if (tagsResponseMeta.current.last_page === tagsResponseMeta.current.current_page) return;
        }

        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...formSchema[fieldName],
                data: [...(lazy ? prev[fieldName].data || [] : [])],
                props: {
                    ...formSchema[fieldName].props,
                    loading: true,
                },
            },
        }));

        const response = await getNewOptions();
        if(fieldName === "country_id") {
            countryResponseMeta.current = response.data.meta;
            setFormFieldsData((prev) => ({ 
                ...prev, 
                country_id: response.data.data.map(({ label, value }: any) => ({
                    label,
                    value: String(value),
                })),
            }));
        }
        if(fieldName === "tags") {
            tagsResponseMeta.current = response.data.meta;
            setFormFieldsData((prev) => ({ 
                ...prev, 
                tags: response.data.data.map(({ label, value }: any) => ({
                    label,
                    value: +value,
                })),
            }));
        }
        setFormSchema((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                data: [
                    ...(lazy ? prev[fieldName].data || [] : []),
                    ...response.data.data.map(({ label, value }: any) => ({
                        label,
                        value: fieldName === "tags" ? +value : String(value),
                    })),
                ],
                props: {
                    ...prev[fieldName].props,
                    loading: false,
                    disabled: false,
                    onFilter,
                    onLazy,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(fieldName, () =>
                    regionService.selectBox({
                        ...(value ? { text: value } : {}),
                    })
                );
                break;
            }
            case 'tags': {
                handleUpdateDropdownOptions(fieldName, () =>
                    tagsService.selectBox({
                        ...(value ? { text: value } : {}),
                        ...(getValues('tags') ? {selected_items : [...getValues('tags') as string[]]} : {})
                    })
                );
                break;
            }
        }
    };

    const onLazy = (fieldName: string) => {
        switch (fieldName) {
            case 'country_id': {
                handleUpdateDropdownOptions(
                    fieldName,
                    () =>
                        regionService.selectBox({
                            page: (countryResponseMeta.current?.current_page || 1) + 1,
                        }),
                    true
                );
                break;
            }
            case 'tags': {
                handleUpdateDropdownOptions(
                    fieldName,
                    () =>
                        tagsService.selectBox({
                            page: (tagsResponseMeta.current?.current_page || 1) + 1,
                        }),
                    true
                );
                break;
            }
        }
    };

    const getFieldOptions = async () => {
        let currenciesData: ISelectBox[] = [];
        let countriesData: ISelectBox[] = [];
        let tagsData: ISelectBox[] = [];

        try {
            if (formFieldsData['currency_id']?.length && formFieldsData['country_id']?.length) {
                currenciesData = formFieldsData.currency_id;
                countriesData = formFieldsData.country_id;
                tagsData = formFieldsData.tags;
            } else {
                const [currencyRes, countryRes , tagsRes] = await Promise.all([
                    currencyService.selectBox(), 
                    regionService.selectBox(),
                    tagsService.selectBox({
                        selected_items : [...getValues('tags') as string[]]
                    })
                ]);
                currenciesData = currencyRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                countriesData = countryRes.data.data.map(({ label, value }) => ({
                    label,
                    value: String(value),
                }));
                tagsData = tagsRes.data.data.map(({ label, value }) => ({
                    label,
                    value: +value,
                }));
                setFormFieldsData((prev) => ({ ...prev, currency_id: currenciesData, country_id: countriesData , tags: tagsData }));
            }

            const newFormSchemaState = {
                ...formSchema,
                currency_id: { ...formSchema.currency_id, data: currenciesData, props: { ...formSchema.currency_id.props, disabled: false } },
                country_id: { ...formSchema.country_id, data: countriesData, props: { ...formSchema.country_id.props, disabled: false, onFilter, onLazy } },
                tags : { ...formSchema.tags, data: tagsData , props: { ...formSchema.tags.props, disabled: false , onFilter, onLazy }}
            };

            setFormSchema(newFormSchemaState);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleUpdateSteps = () => {
        setStepsData((prevStepsData: StepConfig[]) => {
            return prevStepsData.map((step: StepConfig) => {
                if (isValid) {
                    // Hint: we let admin to push next step button and go forward with the form
                    if (step.id === activeStepIndex + 1) {
                        return { ...step, disabled: false };
                    } else return step;
                } else {
                    // Hint: current step has problem, so we disable all next steps till this step become fine
                    if (step.id > activeStepIndex) {
                        return { ...step, disabled: true };
                    } else return step;
                }
            });
        });
    };

    useEffect(() => {
        handleUpdateSteps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    useEffect(() => {
        getFieldOptions();
        if (Object.keys(formValues).length) {
            // Hint: this condition is for those moments we are coming back from next steps.
            Object.entries(formValues).forEach(([key, value]) => setValue(key as keyof IBasicInformationFormSchema, value, { shouldValidate: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isDirty) {
            setStepsData((prevStepsData: StepConfig[]) => {
                return prevStepsData.map((step: StepConfig) => {
                    if (step.id === activeStepIndex) {
                        return { ...step, hasError: false, errors: {} };
                    } else return step;
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
        const subscription = watch((values, event) => {
            if (event.name === 'name') {
                setValue('slug', slugify(values.name || ''));
            }
            const currentValues = getValues();
            const deletedKeys = Object.keys(currentValues).filter(
                (k) => currentValues[k as keyof IBasicInformationFormSchema] === null || currentValues[k as keyof IBasicInformationFormSchema] === ''
            );

            setFormValues((prev) => {
                const newFormValue = { ...prev, ...currentValues };
                Object.keys(newFormValue).forEach((k) => deletedKeys.includes(k) && delete newFormValue[k as keyof IBasicInformationFormSchema]);
                return newFormValue;
            });
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
        <div className="grid justify-content-center">
            <div className="xs:col:12 md:col-6">
                <FormGenerator control={control} errors={errors} schema={formSchema} noBackgroundLayout />
            </div>
        </div>
    );
};

export default BasicInformationCompanyForm;
