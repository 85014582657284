import { ITableColumnData } from 'src/api/types/table';

export const companyDataColumnsRender: ITableColumnData[] = [
    {
        field: 'name',
        sortable: false,
        header: 'Name',
        frozen: true,
        alignFrozen: 'left',
        showCurrency: false,
    },
    {
        field: 'arabic_name',
        sortable: false,
        header: 'Arabic Name',
        showCurrency: false,
    },
    {
        field: 'country_name',
        header: 'Country',
        sortable: false,
        showCurrency: false,
    },
];
