export enum PERMISSION_TYPES {
    // user
    USER_CREATE = 'user|create',
    USER_EDIT = 'user|edit',
    USER_DELETE = 'user|delete',
    USER_SINGLE_VIEW = 'user|single_view',
    USER_LIST_VIEW = 'user|list_view',

    // pending request
    USER_REQUEST_APPROVED_REQUEST = 'user_request_permission|approved_request',
    USER_REQUEST_CANCELED_REQUEST = 'user_request_permission|canceled_request',
    USER_REQUEST_PENDING_REQUEST = 'user_request_permission|pending_request',
    USER_REQUEST_APPROVE = 'user_request_permission|approve',
    USER_REQUEST_CANCEL = 'user_request_permission|cancel',

    // role
    ROLE_CREATE = 'role|create',
    ROLE_EDIT = 'role|edit',
    ROLE_DELETE = 'role|delete',
    ROLE_LIST_VIEW = 'role|list_view',

    // company_people
    COMPANY_PEOPLE_CREATE = 'company_people|create',
    COMPANY_PEOPLE_EDIT = 'company_people|edit',
    COMPANY_PEOPLE_DELETE = 'company_people|delete',
    COMPANY_PEOPLE_LIST_VIEW = 'company_people|list_view',

    // person
    PERSON_CREATE = 'person|create',
    PERSON_EDIT = 'person|edit',
    PERSON_DELETE = 'person|delete',
    PERSON_LIST_VIEW = 'person|list_view',

    // position
    POSITION_CREATE = 'position|create',
    POSITION_EDIT = 'position|edit',
    POSITION_DELETE = 'position|delete',
    POSITION_LIST_VIEW = 'position|list_view',

    // company_financial_report
    COMPANY_FINANCIAL_REPORT_CREATE = 'company_financial_report|create',
    COMPANY_FINANCIAL_REPORT_EDIT = 'company_financial_report|edit',
    COMPANY_FINANCIAL_REPORT_DELETE = 'company_financial_report|delete',
    COMPANY_FINANCIAL_REPORT_LIST_VIEW = 'company_financial_report|list_view',

    // company_share_holder
    COMPANY_SHARE_HOLDER_CREATE = 'company_shareholder|create',
    COMPANY_SHARE_HOLDER_EDIT = 'company_shareholder|edit',
    COMPANY_SHARE_HOLDER_DELETE = 'company_shareholder|delete',
    COMPANY_SHARE_HOLDER_LIST_VIEW = 'company_shareholder|list_view',

    // company_dividend
    COMPANY_DIVIDEND_CREATE = 'company_dividend|create',
    COMPANY_DIVIDEND_EDIT = 'company_dividend|edit',
    COMPANY_DIVIDEND_DELETE = 'company_dividend|delete',
    COMPANY_DIVIDEND_LIST_VIEW = 'company_dividend|list_view',

    // company_holding
    COMPANY_HOLDING_CREATE = 'company_holding|create',
    COMPANY_HOLDING_EDIT = 'company_holding|edit',
    COMPANY_HOLDING_DELETE = 'company_holding|delete',
    COMPANY_HOLDING_LIST_VIEW = 'company_holding|list_view',

    // parent_company_financial
    COMPANY_PARENT_COMPANY_FINANCIAL_CREATE = 'company_parent_financial|create',
    COMPANY_PARENT_COMPANY_FINANCIAL_EDIT = 'company_parent_financial|edit',
    COMPANY_PARENT_COMPANY_FINANCIAL_DELETE = 'company_parent_financial|delete',
    COMPANY_PARENT_COMPANY_FINANCIAL_LIST_VIEW = 'company_parent_financial|list_view',

    // comment
    COMPANY_COMMENT_CREATE = 'company_comment|create',
    COMPANY_COMMENT_EDIT = 'company_comment|edit',
    COMPANY_COMMENT_DELETE = 'company_comment|delete',
    COMPANY_COMMENT_LIST_VIEW = 'company_comment|list_view',
    COMPANY_COMMENT_SINGLE_VIEW = 'company_comment|single_view',

    // setting_financial_information_category
    SETTING_FI_CATEGORY_CREATE = 'setting_financial_information_category|create',
    SETTING_FI_CATEGORY_EDIT = 'setting_financial_information_category|edit',
    SETTING_FI_CATEGORY_DELETE = 'setting_financial_information_category|delete',
    SETTING_FI_CATEGORY_LIST_VIEW = 'setting_financial_information_category|list_view',

    // setting_financial_information_sub_category
    SETTING_FI_SUB_CATEGORY_CREATE = 'setting_financial_information_sub_category|create',
    SETTING_FI_SUB_CATEGORY_EDIT = 'setting_financial_information_sub_category|edit',
    SETTING_FI_SUB_CATEGORY_DELETE = 'setting_financial_information_sub_category|delete',
    SETTING_FI_SUB_CATEGORY_LIST_VIEW = 'setting_financial_information_sub_category|list_view',

    // setting_financial_information_item
    SETTING_FI_ITEM_CREATE = 'setting_financial_information_item|create',
    SETTING_FI_ITEM_DELETE = 'setting_financial_information_item|delete',
    SETTING_FI_ITEM_LIST_VIEW = 'setting_financial_information_item|list_view',

    // setting_sector
    SETTING_SECTOR_CREATE = 'sector|create',
    SETTING_SECTOR_EDIT = 'sector|edit',
    SETTING_SECTOR_DELETE = 'sector|delete',
    SETTING_SECTOR_LIST_VIEW = 'sector|list_view',

    // setting_stock_exchange
    SETTING_STOCK_EXCHANGE_CREATE = 'stock_exchange|create',
    SETTING_STOCK_EXCHANGE_EDIT = 'stock_exchange|edit',
    SETTING_STOCK_EXCHANGE_DELETE = 'stock_exchange|delete',
    SETTING_STOCK_EXCHANGE_LIST_VIEW = 'stock_exchange|list_view',

    // legal_structures_exchange
    SETTING_LEGAL_STRUCTURES_CREATE = 'legal_structure|create',
    SETTING_LEGAL_STRUCTURES_EDIT = 'legal_structure|edit',
    SETTING_LEGAL_STRUCTURES_DELETE = 'legal_structure|delete',
    SETTING_LEGAL_STRUCTURES_LIST_VIEW = 'legal_structure|list_view',

    // media_library
    SETTING_MEDIA_LIBRARY_CREATE = 'media|create',
    SETTING_MEDIA_LIBRARY_EDIT = 'media|edit',
    SETTING_MEDIA_LIBRARY_DELETE = 'media|delete',
    SETTING_MEDIA_LIBRARY_LIST_VIEW = 'media|list_view',

    // company_information
    COMPANY_INFORMATION_CREATE = 'company_information|create',
    COMPANY_INFORMATION_EDIT = 'company_information|edit',
    COMPANY_INFORMATION_DELETE = 'company_information|delete',
    COMPANY_INFORMATION_SINGLE_VIEW = 'company_information|single_view',
    COMPANY_INFORMATION_LIST_VIEW = 'company_information|list_view',
    COMPANY_INFORMATION_APPROVE = 'company_information|approve',
    COMPANY_INFORMATION_NOT_APPROVE = 'company_information|not_approve',

    // company_financial_information
    COMPANY_FI_CREATE = 'company_financial_information|create',
    COMPANY_FI_EDIT = 'company_financial_information|edit',
    COMPANY_FI_DELETE = 'company_financial_information|delete',
    COMPANY_FI_LIST_VIEW = 'company_financial_information|list_view',
    COMPANY_FI_APPROVE = 'company_financial_information|approve',
    COMPANY_FI_EDIT_APPROVED_DATA = 'company_financial_information|edit_approved_data',
    COMPANY_FI_DELETE_APPROVED_DATA = 'company_financial_information|delete_approved_data',

    // company_feed
    COMPANY_FEED = 'company_feed|config',

    // news_source
    SETTING_NEWS_SOURCE_DELETE = 'news_source|delete',
    SETTING_NEWS_SOURCE_LIST_VIEW = 'news_source|list_view',
    SETTING_NEWS_SOURCE_EDIT = 'news_source|edit',
    SETTING_NEWS_SOURCE_CREATE = 'news_source|create',

    // news
    NEWS_DELETE = 'news|delete',
    NEWS_LIST = 'news|list_view',
    NEWS_CREATE = 'news|create',
    NEWS_EDIT = 'news|edit',
    
    // Messages
    MESSAGES_LIST = 'messages|list_view',

    // currency exchange/conversion rate
    CURRENCY_EXCHANGE_LIST = 'exchange_rate|list_view',
    CURRENCY_EXCHANGE_EDIT = 'exchange_rate|modify',

    // screeners
    SCREENERS_LIST = 'screeners|list_view',
    SCREENERS_EDIT = 'screeners|modify',
}
