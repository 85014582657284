import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { parentCompanyFinancialLanguages } from '../Show/CompanyParentCompanyFinancial/AddUpdate/constants/parent-company-financial-languages-dropdown';

export type BasicInformationSchemaFields =
    | 'logo_id'
    | 'name'
    | 'slug'
    | "tags"
    | 'arabic_name'
    | 'short_name'
    | 'arabic_short_name'
    | 'previous_name'
    | 'previous_arabic_name'
    | 'country_id'
    | 'currency_id'
    | 'description'
    | 'arabic_description';
export type BasicInformationSchemaType = Record<BasicInformationSchemaFields, IFormGeneratorSchemaType>;
export const basicInformationSchema: BasicInformationSchemaType = {
    logo_id: {
        label: 'Company Logo',
        placeholder: 'Attach Your Company Logo Picture',
        type: FORM_INPUTS.IMAGE_UPLOADER,
        props: {
            required: true,
            acceptedRatio: 1.1,
            imageConditions: 'Ratio 1 * 1 and Max Size 1 MB',
        },
    },
    name: {
        label: 'English Name',
        placeholder: 'English Name',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
        props: {
            required: true,
        },
    },
    arabic_name: {
        label: 'Arabic Name',
        placeholder: 'Arabic Name',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
        props: {
            required: true,
        },
    },
    previous_name: {
        label: 'Previous English Name',
        placeholder: 'Previous English Name',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    previous_arabic_name: {
        label: 'Previous Arabic Name',
        placeholder: 'Previous Arabic Name',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
    },
    slug: {
        label: 'Slug',
        placeholder: 'Slug',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
        props: {
            required: true,
        },
    },
    tags: {
        label: 'Tags',
        placeholder: 'Select Tags',
        type: FORM_INPUTS.MULTI_SELECT,
        className: 'lg:col-6',
        props: {
            required: true,
            // disabled: true,
            filter: true,
            // lazy: true,
            // options : [],
            showClear: false,
            // panelHeaderTemplate: <></>,
            showSelectAll: false,
        },
    },
    short_name: {
        label: 'English Short Name',
        placeholder: 'English Short Name',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
        props: {
            required: false,
        },
    },
    arabic_short_name: {
        label: 'Arabic Short Name',
        placeholder: 'Arabic Short Name',
        type: FORM_INPUTS.TEXT,
        className: 'lg:col-6',
        props: {
            required: false,
        },
    },
    country_id: {
        label: 'Country',
        placeholder: 'Select Country',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            required: true,
            disabled: true,
            filter: true,
            lazy: true,
        },
    },
    currency_id: {
        label: 'Currency',
        placeholder: 'Select Currency',
        type: FORM_INPUTS.SELECT,
        className: 'lg:col-6',
        props: {
            required: true,
            disabled: true,
        },
    },
    description: {
        label: 'English Description',
        placeholder: 'Write description about your company',
        type: FORM_INPUTS.TEXTAREA,
    },
    arabic_description: {
        label: 'Arabic Description',
        placeholder: 'Write description about your company',
        type: FORM_INPUTS.TEXTAREA,
    },
};
