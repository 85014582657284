import { arabicCharactersRegex } from 'src/constants/regex';
import { getMaxError, getMinError } from 'src/utils/get-yup-errors';
import { string, object, array } from 'yup';

export const validationSchema = () =>
    object().shape({
        name: string().required('Name is required')
            .min(2, getMinError(2, 'Name'))
            .max(80, getMaxError(3, 'Name')),
        arabic_name: string()
            .required('Arabic Name is required')
            .min(2, getMinError(3, 'Arabic Name'))
            .max(80, getMaxError(3, 'Arabic Name'))
            .matches(arabicCharactersRegex, 'Arabic Name must only accept Arabic characters'),
        company_type: array()
            .nullable()
            .min(1, 'At least one company type is required') // Ensures at least one item
            .required('Company Type is required'),
    });