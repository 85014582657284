import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';
import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IGetListPayload, ISelectBox } from '../types/base-types';
import { IScreenerListResponse, TCreateScreenerPayload } from '../types/screeners';
import { ICompanyTypeResponse } from '../types/sectors';
import { ITagListResponse, TCreateTagPayload } from '../types/tags';

export const tagsService = {
    getAllTags(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<ITagListResponse>>(API_ROUTES.TAG.GET_ALL_TAGS, { params });
    },
    createTag(payload: TCreateTagPayload) {
        return AxiosInstance.post<IAxiosResponse<ITagListResponse>>(API_ROUTES.TAG.CREATE, payload);
    },
    delete(id : number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.TAG.DELETE(id));
    },
    deleteScreener(screenerId: number) {
        return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.SCREENER.DELETE_SCREENER(screenerId));
    },
    selectBox(params?: IGetListPayload) {
        return AxiosInstance.get<IAxiosResponse<ISelectBox[]>>(API_ROUTES.TAG.DROPDOWN, { params });
    },
};
