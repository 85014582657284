import { Column } from 'primereact/column';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { tagsService } from 'src/api/services/tags-service';
import { IScreenerListResponse } from 'src/api/types/screeners';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import DashboardTitle from 'src/components/App/DashboardTitle';
import EmptyStateIcon from 'src/components/App/EmptyData/icon';
import Table from 'src/components/App/Table';
import Button from 'src/components/Kit/Button';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { modalActions } from 'src/context/modals';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { MODAL_TYPES } from 'src/types/modals';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import style from '../styles.module.scss';
import { cellRender } from './cellRender';
const TagsList: React.FC = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const service = async (queryParams?: any) =>
        tagsService.getAllTags(queryParams).then((res) => {
            setIsEmpty(res.data.data.length === 0);
            return res;
        });

    const handleRemove = (rowData: IScreenerListResponse, setTableData: Dispatch<SetStateAction<IScreenerListResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await tagsService.delete(rowData.id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IScreenerListResponse[]) => tableData.filter((item: IScreenerListResponse) => item.id !== rowData.id));
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Confirm',
            text: 'Are you sure to delete this Tag?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IScreenerListResponse[], setTableData: Dispatch<SetStateAction<IScreenerListResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IScreenerListResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_DELETE) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Tags List</title>
            </Helmet>
            <DashboardTitle
                title={'Tags List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_CREATE) ? (
                        <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.TAGS.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Tag
                        </Button>
                    ) : null
                }
            />
            <DashboardContentWrapper>
                <div>
                    <Table getTableData={service as any} cellRender={cellRender} suffixCols={suffixCols} />
                </div>
            </DashboardContentWrapper>
        </>
    );
};

export default TagsList;
