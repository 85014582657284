import { classNames } from 'primereact/utils';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { screenerService } from 'src/api/services/screener-service';
import { AddNewItemInSheetImage } from 'src/assets/Images/FinancialInformationLandingImage';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table';
import Button from 'src/components/Kit/Button';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import style from '../styles.module.scss';
import { cellRender } from './cellRender';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IScreenerListResponse } from 'src/api/types/screeners';
import { Column } from 'primereact/column';
import EditIcon from 'src/assets/Icons/EditIcon';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { getAxiosError } from 'src/utils/get-axios-error';
import { MODAL_TYPES } from 'src/types/modals';
import { modalActions } from 'src/context/modals';
const ScreenersList: React.FC = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const service = async (queryParams?: any) =>
        screenerService.getAllScreeners(queryParams).then((res) => {
            setIsEmpty(res.data.data.length === 0);
            return res;
        });

    const handleRemove = (rowData: IScreenerListResponse, setTableData: Dispatch<SetStateAction<IScreenerListResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await screenerService.deleteScreener(rowData.id);
                if (deleteResult.data.success) {
                    setTableData((tableData: IScreenerListResponse[]) => tableData.filter((item: IScreenerListResponse) => item.id !== rowData.id));
                    toast.fire({
                        icon: TOAST_STATUS.SUCCESS,
                        title: deleteResult.data.message,
                    });
                } else {
                    toast.fire({
                        icon: TOAST_STATUS.ERROR,
                        title: deleteResult.data.message,
                    });
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Confirm',
            text: 'Are you sure to delete this Screener?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IScreenerListResponse[], setTableData: Dispatch<SetStateAction<IScreenerListResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IScreenerListResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_EDIT) && (
                            <Button link color="secondary" size="m" iconOnly to={ROUTE_CONSTANTS.SETTINGS.SCREENERS.UPDATE.EDIT_BY_ID(rowData.id).ABSOLUTE}>
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_DELETE) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Screeners List</title>
            </Helmet>
            <DashboardTitle
                title={'Screeners List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.SETTING_SECTOR_CREATE) ? (
                        <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.SCREENERS.ADD_NEW.ROOT.ABSOLUTE}>
                            Add New Screener
                        </Button>
                    ) : null
                }
            />
            <DashboardContentWrapper>
                <div style={{ visibility: isEmpty ? 'hidden' : 'visible' }}>
                    <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />
                </div>

                {isEmpty && (
                    <section className={style['initial-mode-wrapper']}>
                        <div>
                            <AddNewItemInSheetImage />
                        </div>
                        <span className={classNames(style['initial-mode-wrapper__title'], 'mt-5')}>Add Screener</span>
                        <span className={style['initial-mode-wrapper__text']}>
                            Create and manage custom screeners to filter companies based on specific criteria
                        </span>

                        <div className={style['initial-mode-wrapper__buttons']}>
                            <div className={style['initial-mode-wrapper__buttons__button']}>
                                <Button
                                    color="primary"
                                    size="m"
                                    customStyle={{ display: 'flex', alignItems: 'center' }}
                                    to={ROUTE_CONSTANTS.SETTINGS.SCREENERS.ADD_NEW.ROOT.ABSOLUTE}
                                    link
                                >
                                    <i className="ts-plus" style={{ marginRight: '8px', marginTop: '12px' }} />
                                    Add Screener
                                </Button>
                            </div>
                        </div>
                    </section>
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default ScreenersList;
